class HeaderCarousel {
	/**
	 * Create and initialise objects of this class
	 * @param {object} block
	 */
	constructor(block) {
		this.block = block;
		this.init();
	}

	init() {
		this.swiper = new Swiper(this.block.querySelector('.swiper'), {
			slidesPerView: 1.25,
			spaceBetween: 2,
			initialSlide: 2,
			navigation: {
				nextEl: this.block.querySelector('.swiper-button-next'),
				prevEl: this.block.querySelector('.swiper-button-prev'),
			},
			breakpoints: {
				768: {
					slidesPerView: 2.15,
					initialSlide: 2,
				},
				992: {
					slidesPerView: 2.4,
					initialSlide: 3,
				},
			},
		});
	}
}

document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.block-header-carousel').forEach( block => new HeaderCarousel(block) );
});
